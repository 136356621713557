<template>
  <div class="create-page-main">
    <div class="create-form">
      <div class="form-title mb-4">ページ編集</div>
      <b-form :disabled="isLoading">
        <b-form-group id="input-group-5" label="タイトル" label-for="input-5">
          <b-form-input
            v-model="page_title"
            style="color: black"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-8" label="説明:" label-for="input-8">
          <input
            type="file"
            hidden
            ref="selectFile"
            id="idSelectFile"
            accept=".pdf"
            @change="changePDF($event)"
          />
          <div class="position-relative w-100">
            <editor
              id="idEditor"
              @dataEditor="createPage($event)"
              v-bind:isEdit="isEdit"
              class="mb-4"
            ></editor>
            <b-spinner
              v-if="loadingUpload"
              style="
                width: 100%;
                height: 3rem;
                position: absolute;
                top: 50%;
                left: 50%;
              "
              large
            ></b-spinner>
          </div>
        </b-form-group>

        <div class="d-flex justify-content-center mt-4">
          <b-button
            v-on:click.prevent="CreateNewPage()"
            variant="info"
            class="mr-4"
            style="color: white; background: #17a2b8; border-radius: 30px"
          >
            ローディング
          </b-button>
          <b-button
            variant="secondary"
            class="mr-4"
            style="color: white; background: black; border-radius: 30px"
            v-on:click="returnList()"
            >キャンセル</b-button
          >
        </div>
      </b-form>
    </div>

    <!-- <div id="editorjs"></div> -->
  </div>
</template>

<script>
// import { VueEditor } from "vue2-editor";
import { mapGetters, mapActions } from "vuex";
import { Urls } from "../utils/urls.js";
import { Api } from "../utils/http-common.js";
import Editor from "../components/editor/index.vue";
import { eventBus } from "../main";

export default {
  name: "CreatePage",
  components: {
    // VueEditor,
    // draggable,
    Editor,
  },
  data() {
    return {
      page_content: "",
      page_title: "",
      chapter: [],
      chapter_content: "",
      position_chapter: null,
      isLoading: false,
      showBtnView: false,
      title_chapter: null,
      optionChapterEdit: [],
      dataChapterEdit: {},
      loadingUpload: false,
      idShop: null,
      isEdit: false,
    };
  },
  created() {
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  mounted() {
    let container = document.getElementsByClassName("ql-toolbar")[0];
    let item = document.createElement("span");
    item.classList.add("ql-formats");
    let input = document.createElement("button");
    input.addEventListener("click", this.toggleModal);
    input.setAttribute("type", "button");
    input.classList.add("ql-test");
    input.innerHTML = "PDF";
    item.appendChild(input);
    container.appendChild(item);
  },
  computed: {
    ...mapGetters(["success", "message", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
        this.$router.go(-1);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.isLoading = false;
      }
    },
    chapter() {
      this.optionChapterEdit = this.chapter.map((item, index) => {
        return {
          value: index,
          text: item.title,
        };
      });
    },
    titleChapter() {
      this.dataChapterEdit = {
        title: this.chapter[this.titleChapter].title,
        chapter_content: this.chapter[this.titleChapter].chapter_content,
      };
    },
  },
  methods: {
    ...mapActions({ CreatePage: "CreatePage" }),
    async createPage() {
      // let shop_id = this.$route.params.shopId;
      // const formCreatePage = {
      //   shop_id: shop_id,
      //   page_title: this.page_title,
      //   page_content: e,
      //   // chapter: this.chapter,
      // };
      // const request = await this.$store.dispatch("CreatePage", formCreatePage);
      // if (request.success === true) {
      //   this.$router.go(-1);
      // } else {
      //   this.isLoading = false;
      //   this.$toasted.error(request.message);
      // }
    },
    CreateNewPage() {
      this.isLoading = true;
      if (
        this.page_title === null ||
        this.page_title === undefined ||
        this.page_title === ""
      ) {
        this.$toasted.error("ページタイトルは入力必須項目です。");
        this.isLoading = false;
      } else {
        eventBus.$emit("getFormEditor");
      }
    },
    createChapterOther() {
      if (
        this.title_chapter !== "" ||
        this.chapter_content !== "" ||
        this.position_chapter !== ""
      ) {
        this.chapter.push({
          title: this.title_chapter,
          chapter_content: this.chapter_content,
          position_chapter: this.position_chapter,
        });
        this.showBtnView = true;

        this.$toasted.success("正常に登録");
        this.title_chapter = "";
        this.chapter_content = "";
        this.position_chapter = "";
        this.$nextTick(() => {
          this.$bvModal.hide("modal-create-chapter");
        });
      } else {
        this.$toasted.error("チャプター登録に失敗しました");
      }
    },
    returnList() {
      this.$router.go(-1);
    },
    submitEditChapter() {
      this.chapter[this.titleChapter].title = this.dataChapterEdit.title;
      this.chapter[this.titleChapter].chapter_content =
        this.dataChapterEdit.chapter_content;
      this.$nextTick(() => {
        this.$bvModal.hide("modal-view");
      });
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        this.$toasted.error("画像がjpg、jpeg、png　形式である必要があります。");
      } else {
        if (file.size / 1024 / 1024 > 10) {
          this.$toasted.error("画像1枚につき10MBまで");
          resetUploader();
        } else {
          var formData = new FormData();
          formData.append("image", file);
          formData.append("id", this.$route.params.shopId);

          const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
          const method = "POST";
          await Api.adminRequestServer({
            method,
            url,
            data: formData,
          })
            .then((response) => {
              const url = response.data.data; // Get url from response
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    handleImageRemove(file) {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: file,
      });
    },
    toggleModal() {
      document.getElementById("idSelectFile").click();
    },
    async changePDF(e) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("id", this.$route.params.shopId);
      if (e.target.files[0].size / 1024 / 1024 > 10) {
        this.$toasted.error("PDFファイルは10MBを超えこえてはなりません");
      } else {
        this.loadingUpload = true;
        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            this.chapter_content +=
              '<iframe class="ql-pdf" frameborder="0" allowfullscreen="true" src="' +
              url +
              '"></iframe><p><br></p>';
            this.loadingUpload = false;
          })
          .catch(() => {
            this.loadingUpload = false;
          });
      }
    },
  },
};
</script>
